<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import appConfig from "@/app.config";
	import { notificationMethods } from "@/state/helpers";

	export default {
		name: "app",
		page: {
			// All sub component titles will be injected into this template.
			titleTemplate(title) {
				title = typeof title === "function" ? title(this.$store) : title;
				return title ? `${title} | ${appConfig.title}` : appConfig.title;
			},
		},
		methods: {
			clearNotification: notificationMethods.clear,
		},
		watch: {
			/**
			 * Clear the alert message on route change
			 */
			// eslint-disable-next-line no-unused-vars
			$route(to, from) {
				// clear alert on location change
				this.clearNotification();
			},
		},
		mounted() {
			// document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
		}
	};
</script>
<style>
	.align-left {
		text-align: left;
	}
	.align-right {
		text-align: right;
	}
	.align-center {
		text-align: center;
	}
	.apexcharts-yaxis text,
	.apexcharts-xaxis text {
		fill: #212121 !important;
	}
	.table> :not(caption)>*>* {
		padding: 0.35rem 0.5rem !important;
	}
	.card {
		margin-bottom: 0.5rem !important;
	}
	.card-body {
		padding: 1rem 0.75rem 0.75rem !important;
	}
	label {
		font-weight: 400 !important;
		margin-bottom: .1rem !important;
	}
	.mb-2 {
		margin-bottom: 0 !important;
	}
	.col-md-2 {
		padding: 0 4px !important;
	}
	.inner {
		margin: 0px !important;
	}
	.container-fluid {
		padding: 0px !important;
	}
	div.row.mt-4 {
		margin-top: 0.7rem !important;
	}
	div.tab-content.p-3 {
		padding: 0 !important;
	}
	/* .row
	{
		margin: 0px!important;
	} */
	.table th {
		padding: 0.75rem 0.35rem !important;
	}
	.page-title-box {
		padding-bottom: 0.5rem !important;
	}
	.text-primary {
		color: #EB455F !important;
	}
</style>