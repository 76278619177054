import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules,
	// Enable strict mode in development to get a warning
	// when mutating state outside of a mutation.
	// https://vuex.vuejs.org/guide/strict.html
	strict: process.env.NODE_ENV !== 'production',
	state: {
		authToken: null,
	},
	mutations: {
		SET_CURRENT_USER: function(state, newValue) {
			state.authToken = newValue
		},
		logout: function(state) {
			localStorage.clear()
			// localStorage.removeItem('user')
			// localStorage.removeItem('authToken')
			// localStorage.removeItem('corporateId')
			// localStorage.removeItem('menus')
			state.authToken = null
		}
	},
	getters: {
		loggedIn: function() {
			return localStorage.getItem("authToken") !== null
		}
	},
})

export default store
