import store from "@/state/store";

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("../views/pages/account/login"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({
						name: "home",
					});
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/landing",
		name: "landing",
		component: () => import("../views/pages/account/landing"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["loggedIn"]) {
					// Redirect to the home page instead
					next({
						name: "attendance",
					});
				} else {
					// Continue to the login page
					next()
				}
			},
		},
	},
	{
		path: "/forgot-password",
		name: "Forgot-password",
		component: () => import("../views/pages/account/forgot-password"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({
						name: "home",
					});
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
					store.dispatch("logout");
				} else if (
					process.env.VUE_APP_DEFAULT_AUTH === "fakebackend" &&
					confirm("Are you sure you want to logout?")
				) {
					store.dispatch("authfack/logout");
				}
				const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
					route.push("/landing")
				);
				//Navigate back to previous page, or home as a fallback
				next(
					authRequiredOnPreviousRoute
						? {
								name: "attendance",
							}
						: {
								...routeFrom,
							}
				);
			},
		},
	},
	{
		path: "/clients",
		name: "clients",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/clients/clients"),
	},
	{
		path: "/client-dashbord",
		name: "client-dashboard",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/clients/client-dashboard"),
	},
	{
		path: "/",
		name: "home",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/dashboard/index"),
	},
	{
		path: "/form/attendanceEditForm/:attendanceId",
		name: "attendanceForm",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/attendanceEditForm"),
	},
	{
		path: "/form/leaveEditForm/",
		name: "leaveForm",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/leaveEditForm"),
	},
	{
		path: "/tables/attendance/:corporateId?",
		name: "attendance",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/attendance"),
	},
	{
		path: "/tables/leaves",
		name: "leaves",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/leaves"),
	},
	{
		path: "/tables/employees",
		name: "employees",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/employees"),
	},
	{
		path: "/tables/support",
		name: "support",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/support"),
	},
	{
		path: "/onboarding/uploadpan",
		name: "uploadpan",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/uploadpan"),
	},
	{
		path: "/onboarding/uploadpdf",
		name: "uploadpdf",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/uploadpdf"),
	},
	{
		path: "/onboarding/journey/:type/:journeyId",
		name: "journey",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/journey"),
	},
	{
		path: "/onboarding/journey-lists",
		name: "journey-lists",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/journey-list"),
	},
	{
		path: "/onboarding/onboarding-lists",
		name: "onboarding-lists",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/onboarding-list"),
	},
	{
		path: "/onboarding/view/journey/:onboardingId",
		name: "view-onboarding-journey",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/onboarding/view-onboarding-journey.vue"),
	},
	{
		path: "/corporate/corporate-branch/:type/:branchId",
		name: "corporate-branch",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/corporate/corporate-branch"),
	},
	{
		path: "/corporate/corporate-branch-lists",
		name: "corporate-lists",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/corporate/corporate-branch-list"),
	},
	{
		path: "/corporate",
		name: "corporate",
		meta: {
			authRequired: false,
		},
		component: () => import("../views/pages/corporate/corporate"),
	},
	{
		path: "/form/addEmployee",
		name: "add-employee",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/addEmployee"),
	},
	{
		path: "/form/salary-sheet",
		name: "salary-sheet",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/salary-sheet"),
	},
	{
		path: "/tables/salary-sheet-list",
		name: "salary-sheet-list",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/salary-sheet-list"),
	},
	{
		path: "/form/holiday-sheet",
		name: "holiday-sheet",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/holiday-sheet"),
	},
	{
		path: "/tables/holiday-sheet-list",
		name: "holiday-sheet-list",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/tables/holiday-sheet-list"),
	},
	{
		path: "/form/employee/:type",
		name: "employee",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/form/employee.vue"),
	},
	{
		path: "/attendance/view/:employeeId",
		name: "employee-attendance",
		meta: {
			authRequired: true,
		},
		component: () => import("../views/pages/attendance/employee-detailed-attendance.vue"),
	},
	{
		path: "/tables/reimbursement-sheet-list",
		name: 'reimbursement-list',
		meta: {
			authRequired: true,
		},
		component: () => import('../views/pages/tables/reimbursement-sheet-list.vue'),
	},
];
