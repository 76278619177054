export default {
	getOwnerList() {
		// alert();
		this.axios.get("getOwnerList")
		.then((result)=>{
			return this.ownerList = result.data.ownerArr;
		});
	},

	// alertFunction(alertvariant,notiTitle,notiText){
	//     // alert(this.showDismissibleAlert);
	//     // this.showDismissibleAlert = true;
	//     // this.alertvariant = alertvariant;
	//     // this.notiTitle = notiTitle;
	//     // this.notiText = notiText;
	// }

	getNearByRestaurantOrCafe(latitude,longitude,flag) {
		if (flag==1) {
			this.disabled = false;
		}
		this.axios
		.post(
			"getNearByRestaurant", 
			{'latitude':latitude,'longitude':longitude,'flag':flag} // flag =>restaurant only, 2=>cafeteria
		)
		.then((response) => {
			if (flag==1) {
				this.tableData = response.data.data;  
			} else {
				this.cafeteriaList = response.data.data;   
			}
		});
	},

	substringFunction(string) {
		if (string.length > 25) {
			string = string.substring(0, 24) + "...";
			return string;
		}
	},

	previewImage: function(event) {
		// console.log(event);
		// Reference to the DOM input element
		var input = event.target;
		// Ensure that you have a file before attempting to read it
		if (input.files && input.files[0]) {
			// create a new FileReader to read this image and convert to base64 format
			var reader = new FileReader();
			// Define a callback function to run, when FileReader finishes its job
			reader.onload = (e) => {
				// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
				// Read image as base64 and set to imageData
				this.imageData = e.target.result;
			}
			// Start the reader job - read file as a data url (base64 format)
			//  console.log(input.files[0]);
			reader.readAsDataURL(input.files[0]);
			this.encodeImage = input.files[0];
		}
	},

	getAddressFieldsFromPlacesApi(placesApiresult) {
		// var country= "";
		// var state = "";
		//var city = "";
		//var pincode ="";
			
		for (var i=0; i < placesApiresult.address_components.length;i++) {
			var addressType = placesApiresult.address_components[i].types[0];
			if (addressType=="locality") {
				this.city = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="administrative_area_level_1") {
				this.state = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="country") {
				this.country = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="postal_code") {
				this.pincode = placesApiresult.address_components[i].long_name;
			}
		}
		//console.log(this.city);
		this.posts.pincode = this.pincode;
		this.posts.country = this.country;
		this.posts.state = this.state;
		this.posts.city = this.city;
	}
	
}

export const func = {
	formatPrice: (value) => {
		var formatter = new Intl.NumberFormat('en-IN', {
			style: 'currency',
			currency: 'INR',
			minimumFractionDigits: 2
		});
		return formatter.format(value);
	},

	formatNumber: (num) => {
		var formatter = new Intl.NumberFormat('en-IN');
		return formatter.format(num);
	},

	formatCommaSepNum: (num) => {
		var formatter = new Intl.NumberFormat('en-IN',{
			maximumFractionDigits: 0
		});
		return formatter.format(num);
	},

	indianCurrencyFormat(number) {
		var converted = 0;
		number.toLocaleString('en-IN');
		converted = number.toLocaleString('en-IN', {
			maximumFractionDigits: 2,
			style: 'currency',
			currency: 'INR'
		});
		return converted;
	},

	getAddressFieldsFromPlacesApi(placesApiresult){
		// var country= "";
		// var state = "";
		//var city = "";
		//var pincode ="";
		for(var i=0; i < placesApiresult.address_components.length;i++) {
			var addressType = placesApiresult.address_components[i].types[0];
			if (addressType=="locality") {
				this.city = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="administrative_area_level_1") {
				this.state = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="country") {
				this.country = placesApiresult.address_components[i].long_name;
			}
			if (addressType=="postal_code") {
				this.pincode = placesApiresult.address_components[i].long_name;
			}
		}
		//console.log(this.city);
		this.posts.pincode = this.pincode;
		this.posts.country = this.country;
		this.posts.state = this.state;
		this.posts.city = this.city;
	}
}