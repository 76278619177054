import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import router from "./router"

import vco from "v-click-outside"
import VueApexCharts from "vue-apexcharts"
import VueSweetalert2 from "vue-sweetalert2"
import VueSlideBar from "vue-slide-bar"
import Vuelidate from "vuelidate"
import i18n from "./i18n"
import store from "@/state/store"
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"
// import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner"
// import { parseUrl } from "@aws-sdk/url-parser"
// import { Sha256 } from "@aws-crypto/sha256-browser"

// Timezon
import moment from 'moment-timezone'

import App from "./App.vue"
// As a plugin
import VueMask from "v-mask"
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps"
import Lightbox from "vue-easy-lightbox"

//// axois imported globally ////////
import axios from "axios"
import VueAxios from "vue-axios"

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('authToken')

Vue.use(VueAxios, axios)

//////// menu bar /////////////////////////
import VueSidebarMenu from "vue-sidebar-menu"
import "vue-sidebar-menu/dist/vue-sidebar-menu.css"
Vue.use(VueSidebarMenu)
/////// Download excel ///////////////////
import JsonExcel from "vue-json-excel"
Vue.component("downloadExcel", JsonExcel)
Vue.use(JsonExcel)
//////// Global variable for alert noti ///////////////
Vue.prototype.$alertText = "hiii"

// Default system timezone
//console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone)
Vue.prototype.$moment = moment

Vue.mixin({
	data: function() {
		return {
			showDismissibleAlert: true,
			alertvariant: "success",
			notiTitle: "",
			notiText: "",
		};
	},
});

Vue.use(Lightbox);
Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
		libraries: "places",
	},
	installComponents: true,
});

import { func } from "./helpers/helper.js"
Vue.prototype.$func = func

import { initFirebaseBackend } from "./helpers/firebase/authUtils"

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend"

const firebaseConfig = {
	apiKey: process.env.VUE_APP_APIKEY,
	authDomain: process.env.VUE_APP_AUTHDOMAIN,
	databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
	projectId: process.env.VUE_APP_PROJECTId,
	storageBucket: process.env.VUE_APP_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_APPId,
	measurementId: process.env.VUE_APP_MEASUREMENTID,
}

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
	initFirebaseBackend(firebaseConfig)
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
	configureFakeBackend()
}

import "@/assets/scss/app.scss"

Vue.component("VueSlideBar", VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component("apexchart", VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)

Vue.use(require("vue-chartist"))
Vue.use(VueMask)

Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToast: 10,
	newestOnTop: true,
})

// const bucket = "staging-munshi"
// const region = "ap-south-1"
// const apiKey = "AKIA3AANH4NRYIZYMSWQ"
// const secretKey = "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"
// const key = "LfwTA6wlvKgZx76FVgB6HOtT1NHNm1TnzYTomWJt.pdf"

// Vue.prototype.$s3ObjectUrl = parseUrl(`https://${bucket}.s3.${region}.amazonaws.com/${key}`)
// const credentials = {
// 	accessKeyId: apiKey,
// 	secretAccessKey: secretKey,
// }
// Vue.prototype.$presigner = new S3RequestPresigner({
// 	credentials,
// 	region,
// 	//sha256: Hash.bind(null, "sha256")
// 	sha256: Sha256
// })

Vue.prototype.$bucket = process.env.VUE_APP_AWS_BUCKET
Vue.prototype.$secretAccessKey = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
Vue.prototype.$accessKeyId = process.env.VUE_APP_AWS_ACCESS_KEY
Vue.prototype.$region = process.env.VUE_APP_AWS_REGION


new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount("#app")
